import $ from 'jquery'
import { Fancybox } from "@fancyapps/ui";
const gallerySlider = $('.js-gallery-carousel-slider')

Fancybox.bind("[data-fancybox]")

function initGallerySlider(){
  if (gallerySlider.length) {
    gallerySlider.slick({
      arrows: true,
      slidesToShow: 2,
      slidesToScroll: 1,
      dots: false,
      prevArrow: '<button type="button" class="slick-prev"><i class="icon arrow-left"></i></button>',
      nextArrow: '<button type="button" class="slick-next"><i class="icon arrow-right"></i></button>',
      responsive: [
        {
          breakpoint: 960,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          }
        }
      ]
    })
  }
}

$(document).ready( initGallerySlider )