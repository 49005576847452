import $ from 'jquery'

const servicesSlider = $('.js-services-slider')

function initServicesSlider(){
  if (servicesSlider.length) {
    servicesSlider.slick({
      arrows: true,
      slidesToShow: 4,
      slidesToScroll: 4,
      dots: false,
      prevArrow: '<button type="button" class="slick-prev"><i class="icon arrow-left"></i></button>',
      nextArrow: '<button type="button" class="slick-next"><i class="icon arrow-right"></i></button>',
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
      ]
    })
  }
}

$(document).ready( initServicesSlider )
