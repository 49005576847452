import $ from 'jquery'

$(document).ready(initTestimonialsSlider)

function initTestimonialsSlider() {
  const sliderWrapper = $('.js-testimonials-slider')
  const prevButton = $('.js-testimonials-slider-prev')
  const nextButton = $('.js-testimonials-slider-next')
  const activeSlide = $('.js-testimonials-slider-page')
  const navButtons = $('.js-slider-buttons')
  const slideCount = sliderWrapper.find('.testimonials__slider-single').length

  if (sliderWrapper.length) {

    if (slideCount < 2) {
      navButtons.hide()
    }

    sliderWrapper.slick({
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: false,
      dots: false,
   })

    sliderWrapper.on('afterChange', function (event, slick, currentSlide) {
      activeSlide.text(currentSlide + 1)
    })

    prevButton.click(function () {
      sliderWrapper.slick('slickPrev')
    })

    nextButton.click(function () {
      sliderWrapper.slick('slickNext')
    })
  }
}
