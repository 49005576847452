const leverApiUrl = 'https://api.lever.co/v0/postings/wra-ca?mode=json'

function appendJobs(jobs) {
  for (const [index, job] of jobs.entries()) {
    $('.js-jobs-list').append(
      `
      <div class="accordion__single-item js-accordion-item">
        <h4 class="accordion__single-item-title">
          <button aria-expanded="false" aria-controls="section-${index}-${index}" id="accordion-${index}-${index}">
            <span class="u-text-bold">${job.text} - ${job.categories.location}</span> <span class="accordion__single-item-icon"></span>
          </button>
        </h4>
        <div class="accordion__single-item-content" id="section-1-1" role="region" aria-labelledby="accordion-1-1">
            <h5 class="heading--lg">${job.text}</h5>
            <div class="u-text-bold u-color-terracota">${job.categories.location}</div>
            <div>${job.categories.team}</div>
            <div class="u-mb2">${job.categories.commitment}</div>
            <div class="u-mb2">${job.description}</div>
            <a href="${job.hostedUrl}" class="button button--text button--plus" target="_blank">Apply</a>
        </div>
      </div>
      `
    );
  }
}

function fetchJobs() {
  $.ajax({
    dataType: "json",
    url: leverApiUrl,
    success: function (data) {
      appendJobs(data);
    }
  })
}

$(document).ready(function () {
  if (!$('.js-jobs-list').length) {
    return
  }
  fetchJobs()
})
