import $ from 'jquery'

const projectsSlider = $('.js-projects-slider')

function initProjectsSlider(){
  if (projectsSlider.length) {
    projectsSlider.slick({
      arrows: true,
      slidesToShow: 3,
      slidesToScroll: 3,
      dots: false,
      prevArrow: '<button type="button" class="slick-prev"><i class="icon arrow-left"></i></button>',
      nextArrow: '<button type="button" class="slick-next"><i class="icon arrow-right"></i></button>',
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
      ]
    })
  }
}

$(document).ready( initProjectsSlider )
